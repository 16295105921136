@font-face {
  font-family: 'Axiforma Regular';
  src: url('./assets/axiforma/Axiforma-Regular.woff2') format('woff2'),
  url('./assets/axiforma/Axiforma-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* General styles */
.App {
  text-align: center;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
}

/* Background with overlay content */
.content {
  position: relative;
  height: 100vh;
  width: 100vw;
  background: url('./assets/background.jpg') no-repeat center center fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically centers children */
  align-items: center;     /* Horizontally centers children */
}

/* Header Image */
.header-image {
  position: absolute;
  top: 20px;
  max-width: 40%;
}

/* Play Button */
.play-button {
  cursor: pointer;
  width: 100px;
  height: 100px;
}

/* Footer Text */
.footer-text {
  font-family: 'Axiforma Regular', sans-serif;
  position: absolute;
  bottom: 20px;
  font-size: 30px;
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
}

/* Video Overlay */
.video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.video-player {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px; /* Optional: Rounds video corners */
}
